<template>
<!-- eslint-disable -->
	<div class="register">
		<div class="mt-16">
			<h1 class="text-center" style="color: white; font-weight: 600; font-size: 40px;">OASIS DAILY</h1>
			<v-card
				class="mx-auto my-8"
				max-width="330"
				height="525"
			>
			<h3 class="text-center pt-8">ユーザ登録</h3>
				<v-card-text>
					<v-row>
						<input type="text" class="input pl-4 mt-12" v-model="registerData.name" placeholder="NAME">
					</v-row>
					<v-row>
						<input type="text" class="input pl-4 mt-6" v-model="registerData.password" placeholder="PASSWORD">
					</v-row>
					<v-row>
						<input type="text" class="input pl-4 mt-6" v-model="registerData.confirm" placeholder="CONFIRM(確認)">
					</v-row>
				</v-card-text>
				<p class="mt-5 ml-10 mb-2">誕生日</p>
				<BirthdayPicker class="ml-8" style="max-width: 280px;" @changeBirth="changeBirth"/>
				<v-btn
					:loading="loading"
					:disabled="loading"
					color="indigo"
					outlined
					@click="signUp()"
					style="margin: 45px 120px;"
				>
					SIGN UP
				</v-btn>
			</v-card>
		</div>
		<v-btn
			color="white"
			outlined
			@click="backLogin()"
			style="margin-top: 35px; margin-left: 275px;"
		>
			<v-icon>mdi-arrow-left</v-icon>BACK
		</v-btn>
		<Footer style="background-color: #00CC33 !important"/>


	</div>	
</template>
<script>
/* eslint-disable */
import * as api from '@/api.js'
import Footer from '@/components/Footer.vue'
import BirthdayPicker from '../../components/BirthdayPicker.vue'
export default {
	name: 'Register',
	components: { Footer, BirthdayPicker },
    data () {
		return {
			loader: null,
			loading: false,
		// 登録内容
			registerData: {
				name: '',
				password: '',
				confirm: '',
			},
			birth: '',
		// 画面側起動用
			// name: '',
		}
    },
	methods: {
		signUp() {
			if (this.registerData.password !== this.registerData.confirm) {
				this.pop('エラー', '確認用パスワードが一致していません。')
				return
			}
			api.register.register({ name: this.registerData.name, password: this.registerData.password }).then((res) => {
                switch(res.data.status) {
                    case 200:
						this.pop('登録が成功しました！', res.data.content.message)
                        break
                    case 500:
						this.pop('登録が失敗しました！', res.data.error[0])
                        break
                }
			}).catch()
		},

		pop(title, message) {
			this.$store.dispatch('setDialog', { title: title, message: message });
		},



		clear() {
			this.registerData.name = ''
			this.registerData.password = '',
			this.registerData.confirm = ''
		},
		backLogin() {
			this.$router.push( { name: 'Login' })
		}
	},
	created() {
		this.clear()
	},
    watch: {
		loader () {
			const l = this.loader
			this[l] = !this[l]

			setTimeout(() => (this[l] = false), 1000)

			this.loader = null
		},
    },
}
</script>