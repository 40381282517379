<template>
<!-- eslint-disable -->
	<v-footer
		dark
		:color="this.color"
		min-height="65px"
		width="100%"
		style="position: absolute; bottom: 0; opacity: 0.75;"
	>
		<v-icon class="ml-10" @click="progress()">mdi-chart-arc</v-icon>
		<v-spacer></v-spacer>
		<v-icon>mdi-calendar</v-icon>
		<v-spacer></v-spacer>
		<v-icon class="mr-10">mdi-calendar</v-icon>
	</v-footer>
</template>
<script>
export default {
    name: 'Footer',
	data () {
		return {}
	},
	methods: {
		progress() {
			this.$router.push({ name: 'Progress' })
		},
	},
	computed: {
        color() {
            return this.$store.getters.loginInfo.color
        }
    },
}
</script>
